import React from 'react';
import AbstractReactComponent from "../../../core/view/AbstractReactComponent";
import {
    DB_NODE_INFO, DB_NODE_TYPE, getDefaultWinConfig,
    hideAllProjectExplorer,
    PROJECT_DB_INFO,
    PROJECT_FILE_INFO,
    WIN_CONFIG,
    windowConfig
} from "../../define/types";
import {EVENT} from "../../../core/define/types";
import {bsp, SERVICE_MAP} from "../../api/BIServiceMap";
import DragHandler from "../drag/DragHandler";


interface COMM_PROPS{
    config:WIN_CONFIG,
    projects:PROJECT_DB_INFO[],    //전체 프로젝트
    selected?:PROJECT_DB_INFO,     // 선택된 프로젝트
}
/**
 * 프로젝트 좌측 얇은 탭
 */
export default class ProjectDBExplore extends AbstractReactComponent<any> {

    constructor(props:any) {
        super(props);
        this.state = {projects:[], config:getDefaultWinConfig()};
        // this.init();
    }

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {projects:[], config:getDefaultWinConfig()};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'projects', 'selected', 'config');
        return p;
    }

    on(event:EVENT, data:any, spread?:boolean){
        super.on(event, data, spread);
        if(event === EVENT.CONFIG_RELOAD) {
            try{
                this.setState({config: windowConfig});
            }catch(e){
                console.log('Config not found in props');
            }
        }
    }

    createProject():void {
        bsp.run(SERVICE_MAP.DB_NEW, null);
    }

    closeProject(isShow:boolean):void {
        if(isShow) hideAllProjectExplorer();
        windowConfig.PROJECT_DB_EXPLORE.SHOW = isShow;
        this.setState({config: windowConfig});
        this.getRootContext().broadcast(EVENT.CONFIG_RELOAD);
    }

    getProjectChildJSX(files:DB_NODE_INFO[]):JSX.Element {
        let layout: JSX.Element = <></>;

        if(files != null && files.length > 0) {
            layout = (<>
                <ul>
                    {files.map((file:DB_NODE_INFO, idx:number)=>{
                        let ico = 'ico-db-folder';
                        if(file.DB_NODE_TYPE === DB_NODE_TYPE.SCHEMA) ico = 'ico-db-schema';
                        if(file.DB_NODE_TYPE === DB_NODE_TYPE.FOLDER) ico = 'ico-db-folder';
                        if(file.DB_NODE_TYPE === DB_NODE_TYPE.TABLE) ico = 'ico-db-table';
                        if(file.DB_NODE_TYPE === DB_NODE_TYPE.COLUMN) ico = 'ico-db-column';
                        if(file.DB_NODE_TYPE === DB_NODE_TYPE.PK_COLUMN) ico = 'ico-db-column-pk';
                        return (<li key={idx}>
                            <div className="tree-list-row">
                                <div className="tree-row">
                                    <div className="tree-row-indent"></div>
                                    <div className="tree-row-twistie collapsible"></div>
                                    <div className={`tree-row-contents icon ${ico}`}>
                                        <div className="label-container">
                                            <span>{file.DB_NODE_NAME}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>);
                    })}
                </ul>
            </>);
        }else{
            layout = (<></>);
        }
        return layout;
    }

    getProjectJSX():JSX.Element {
        let layout: JSX.Element = <></>;
        if(this.state.projects.length === 0) {
            layout = (<div className="tree-list show-file-icons mouse-support">
                <div className={'no-project'}><a onClick={()=>this.createProject()}>New Connection</a></div>
            </div>);
        } else {
            layout = (<>
                <div className="tree-list show-file-icons mouse-support">
                    <ul className="tree-list-rows">
                        {this.state.projects.map((project: PROJECT_DB_INFO, idx: number) => {
                            return (<li key={idx}>
                                <div className="tree-list-row">
                                    <div className="tree-row">
                                        <div className="tree-row-indent"></div>
                                        <div className="tree-row-twistie collapsible"></div>
                                        <div className="tree-row-contents icon ico-db-oracle">
                                            <div className="label-container">
                                                <span>{project.DB_CONNECTION.CONN_NAME}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.getProjectChildJSX(project.CHILDS)}
                            </li>);
                        })}
                    </ul>
                </div>
            </>);
        }
        return layout;
    }

    dragCallback = (width:number, height:number):void => {
        windowConfig.PROJECT_DB_EXPLORE.WIDTH = width;
        this.setState({config: windowConfig});
    }

    render(): JSX.Element {
        let layout: JSX.Element = <></>;

        let statusCss = '';
        if(windowConfig.PROJECT_DB_EXPLORE.SHOW) statusCss += ' on';

        layout = (<>

            {/* Database */}
            <article id="dbConnect-panel" className={`panel left ${statusCss}`} style={{ width: this.state.config.PROJECT_DB_EXPLORE.WIDTH }}>
                <div className="panel-bar">
                    <h2>Database</h2>
                    <div>
                        <button className="btn-setting" >panel option</button>
                        <button className="btn-close" onClick={()=>this.closeProject(false)}>close</button>
                        <ul className="menu panel-option-menu">
                            <li>
                                <button className="checked"><span>Show Column Logical Name</span></button>
                            </li>
                            <li>
                                <button className="checked"><span>Show Column Type</span></button>
                            </li>
                            <li></li>
                            <li>
                                <button className="checked"><span>Show Function Type</span></button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="panel-body">
                    {
                        /* file tree explorer */
                        this.getProjectJSX()
                    }
                </div>
                <DragHandler context={this} width={this.state.config.PROJECT_DB_EXPLORE.WIDTH} callback={this.dragCallback}></DragHandler>
            </article>

        </>);
        return layout;
    }
}
