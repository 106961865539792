// =============== AI BUILDER 메뉴 ===============
import {SERVICE_MAP} from "../api/BIServiceMap";

export enum MENU_TYPE{
    HEAD='HEAD',
    MENU='MENU',
    SUB_MENU='SUB_MENU',
    DIVIDE='DIVIDE'
}
export interface MENU{
    NAME:string,
    TYPE:MENU_TYPE,
    SERVICE:SERVICE_MAP,
    CHILD?:MENU[],
    SHOW:boolean,
    SHORTCUT?:string
}
export let APP_MENUS:MENU[] = [
    {NAME:'BI-TTS', TYPE:MENU_TYPE.HEAD, SERVICE:SERVICE_MAP.NONE, SHOW:true, CHILD:[
        {NAME:'BI-TTS에 대하여', TYPE:MENU_TYPE.MENU, SERVICE:SERVICE_MAP.ABOUT_BITTS, SHOW:true, SHORTCUT:'F1'},
        {NAME:'설정', TYPE:MENU_TYPE.MENU, SERVICE:SERVICE_MAP.SETTING, CHILD:[], SHOW:true},
        {NAME:'', TYPE:MENU_TYPE.DIVIDE, SERVICE:SERVICE_MAP.NONE, SHOW:true},
        {NAME:'라이센스', TYPE:MENU_TYPE.MENU, SERVICE:SERVICE_MAP.LICENSE, CHILD:[], SHOW:true},
        {NAME:'종료', TYPE:MENU_TYPE.MENU, SERVICE:SERVICE_MAP.CLOSE_BITTS, CHILD:[], SHOW:true},
    ]},
    {NAME:'파일', TYPE:MENU_TYPE.HEAD, SERVICE:SERVICE_MAP.NONE, SHOW:true, CHILD:[
        {NAME:'새로 만들기', TYPE:MENU_TYPE.MENU, SERVICE:SERVICE_MAP.PROJECT_NEW, SHOW:true},
        {NAME:'프로젝트 닫기', TYPE:MENU_TYPE.MENU, SERVICE:SERVICE_MAP.PROJECT_CLOSE, CHILD:[], SHOW:true},
        {NAME:'', TYPE:MENU_TYPE.DIVIDE, SERVICE:SERVICE_MAP.NONE, SHOW:true},
        {NAME:'내보내기', TYPE:MENU_TYPE.MENU, SERVICE:SERVICE_MAP.PROJECT_EXPORT, CHILD:[], SHOW:true},
    ]},
    {NAME:'도움말', TYPE:MENU_TYPE.HEAD, SERVICE:SERVICE_MAP.NONE, SHOW:true, CHILD:[
        {NAME:'도움말', TYPE:MENU_TYPE.MENU, SERVICE:SERVICE_MAP.HELP, SHOW:true},
        {NAME:'시작하기', TYPE:MENU_TYPE.MENU, SERVICE:SERVICE_MAP.HELP_START, CHILD:[], SHOW:true},
        {NAME:'단축키', TYPE:MENU_TYPE.MENU, SERVICE:SERVICE_MAP.SHORT_CUT, CHILD:[], SHOW:true},
        {NAME:'', TYPE:MENU_TYPE.DIVIDE, SERVICE:SERVICE_MAP.NONE, SHOW:true},
        {NAME:'내보내기', TYPE:MENU_TYPE.MENU, SERVICE:SERVICE_MAP.PROJECT_EXPORT, CHILD:[], SHOW:true},
    ]},
];
// =============== //AI BUILDER 메뉴 ===============

