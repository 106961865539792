import BIServiceWorker from "../BIServiceWorker";
import {FETCH_EVENT} from "../../../core/transaction/Transaction";
import {EVENT} from "../../../core/define/types";


export default class SETTING extends BIServiceWorker {

    run(param:any, callback?:FETCH_EVENT): void {
        console.log('SETTING run', param, callback);
        this.getContext()?.broadcast(EVENT.OPEN_SETTING, {});
    }
}

