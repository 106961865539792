import React from 'react';
import AbstractReactComponent from "../core/view/AbstractReactComponent";
import {EVENT} from "../core/define/types";
import {getDefaultWinConfig, WIN_CONFIG} from "./define/types";
import ContentBody from "./layout/content/ContentBody";
import ProjectExplore from './layout/project/ProjectExplore';
import MainHeader from "./layout/MainHeader";
import MainLeftToggle from "./layout/MainLeftToggle";
import MainRightToggle from "./layout/MainRightToggle";
import MainFooter from "./layout/MainFooter";
import ProjectDBExplore from "./layout/project/ProjectDBExplore";
import WorkspaceExplore from "./layout/workspace/WorkspaceExplore";
import WorkspaceSetting from "./layout/workspace/WorkspaceSetting";
import WorkspaceAssistant from "./layout/workspace/WorkspaceAssistant";
import ContentTabs from "./layout/content/ContentTabs";
import {bsp} from "./api/BIServiceMap";

interface COMM_PROPS{
    config:WIN_CONFIG
}
export default class Main extends AbstractReactComponent<any> {

    constructor(props:any) {
        super(props);
        this.state = {main:null, config:getDefaultWinConfig()};
        // this.init();
        bsp.setRootContext(this);
    }

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {config:getDefaultWinConfig()};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'config');
        return p;
    }

    on(event: EVENT, data: any, spread?: boolean) {
        super.on(event, data, spread);
        if(event === EVENT.ON){
            if(data.type === 'player-select'){

            }
        }
    }

    init():void{
        //로딩 시작
        this.getResponse('/CFG/CFG010001', {}, {
            success:(res:any, data:any)=>{
                // session.setSession(result);
                console.log(res, data);
            }
        });
    }

    render(): JSX.Element {
        return (
            <div id="app" className="app">
                <MainHeader context={this}></MainHeader>
                <MainLeftToggle context={this}></MainLeftToggle>
                <main>
                    <div className="full">
                        <ProjectExplore context={this}></ProjectExplore>
                        <ProjectDBExplore context={this}></ProjectDBExplore>
                        {/* edit */}
                        <article id="edit-panel" className="panel full on">
                            <ContentTabs context={this}></ContentTabs>
                            <ContentBody context={this}></ContentBody>
                        </article>
                        <WorkspaceAssistant context={this}></WorkspaceAssistant>
                        <WorkspaceExplore context={this}></WorkspaceExplore>
                        <WorkspaceSetting context={this}></WorkspaceSetting>
                    </div>
                </main>
                <MainRightToggle context={this}></MainRightToggle>
                <MainFooter context={this}></MainFooter>
            </div>
        );
    }
}
