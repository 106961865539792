import React from 'react';
import AbstractReactComponent from "../core/view/AbstractReactComponent";
import {googleCertifier, GoogleCredential} from "./auth/GoogleCertifier";
import {nerdfoxCertifier} from "./auth/NerdFoxCertifier";
import {EVENT} from "../core/define/types";

interface COMM_PROPS{
    credentials?: GoogleCredential
}
export default class Intro extends AbstractReactComponent<any> {
    state: COMM_PROPS = {};

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {};
        AbstractReactComponent.pullups(prevState, nextProps, p, "credentials");
        return p;
    }

    on(event: EVENT, data: any, spread?: boolean) {
        super.on(event, data, spread);
        if(event === EVENT.ON){
            if(data.type === 'credentials'){
                this.setState({credentials:data.data});
            }
        }
    }

    public login(provider:string):void {
        if(provider === 'google') {
            googleCertifier.signJwtIn((e:EVENT, res:GoogleCredential)=>{
                console.log(res)
                this.setState({credentials:res});
                this.getRootContext().on(EVENT.ON, {sender:'context', type:"credentials", data:res});
                // nerdfoxCertifier.setToken(res.credential.accessToken);
                nerdfoxCertifier.setToken(res.credential.idToken);
                nerdfoxCertifier.signJwtIn((e:EVENT, res:any)=>{
                    console.log(e, res)
                })
            });
        }else{
            document.location.href = '/Main';
        }
    }

    render(): JSX.Element {
        return (
            <>
                <div id="app" className="app app-main">
                    <div className={'login-wrap'}>
                        <div className={'login-box'}>
                            <div className={'login-header'}>
                                <h1>BI-TTS</h1>
                                <h2>AI Text To SQL BUILDER</h2>
                            </div>
                            <div className={'login-body'}>
                                <button className={'scaled-el google'} onClick={()=>this.login('google')}>구글 로그인</button>
                                <button className={'scaled-el email'} onClick={()=>this.login('email')}>이메일 로그인</button>
                            </div>
                            <div className={'login-footer'}>
                                <p>© 2024 BI-TTS. (주) 범익 All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
