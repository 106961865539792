import React from 'react';
import {getDefaultWinConfig, WIN_CONFIG, windowConfig} from "../../define/types";
import AbstractReactComponent from "../../../core/view/AbstractReactComponent";
import Main from "../../Main";
import {EVENT} from "../../../core/define/types";

export enum DRAG_DIRECTION {
    HORIZONTAL_LEFT,    //우에서 좌로
    HORIZONTAL_RIGHT,   //좌에서 우로
    VERTICAL_UP,        //아래에서 위로
    VERTICAL_DOWN,      //위에서 아래로
}
interface COMM_PROPS{
    x:number, y:number, dragging:boolean, width:number, height:number, callback?:Function, directionX:DRAG_DIRECTION, directionY:DRAG_DIRECTION
}
/**
 * 프로젝트 왼쪽 프로젝트 Explorer, DB Explorer 창
 */
export default class DragHandler extends AbstractReactComponent<any> {
    startX:number = 0;
    startY:number = 0;
    startWidth:number = 0;
    startHeight:number = 0;
    currentWidth:number = 0;
    currentHeight:number = 0;
    constructor(props:any) {
        super(props);
        this.state = {
            width:300, height:300, x:0, y:0, dragging: false, directionX:DRAG_DIRECTION.HORIZONTAL_RIGHT, directionY:DRAG_DIRECTION.VERTICAL_UP
        };
        this.startWidth = this.state.width;
        this.startHeight = this.state.height;
        this.currentWidth = this.state.width;
        this.currentHeight = this.state.height;
    }

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {width:300, height:300, x:0, y:0, dragging: false, directionX:DRAG_DIRECTION.HORIZONTAL_RIGHT, directionY:DRAG_DIRECTION.VERTICAL_UP};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'width', 'height', 'x', 'y', 'dragging', 'callback', 'directionX', 'directionY');
        return p;
    }

    on(event:EVENT, data:any, spread?:boolean){
        super.on(event, data, spread);
        if(event === EVENT.CONFIG_RELOAD) {
            try{
                this.setState({config: windowConfig});
            }catch(e){
                console.log('Config not found in props');
            }
        }
    }

    startDrag = (e: { clientX: number; clientY: any; }) => {
        this.setState({ dragging: true });
        this.startX = e.clientX;
        this.startY = e.clientY;
        this.startWidth = this.state.width;
        this.startHeight = this.state.height;
    }

    onDrag = (e: { clientX: number; clientY: any; }) => {
        if (!this.state.dragging) return;
        let deltaWidth = e.clientX - this.startX;  // 가로 방향 마우스 이동 거리 계산
        let deltaHeight = e.clientY - this.startY; // 세로 방향 마우스 이동 거리 계산
        if(this.state.directionX === DRAG_DIRECTION.HORIZONTAL_LEFT) deltaWidth = -deltaWidth;
        if(this.state.directionY === DRAG_DIRECTION.VERTICAL_DOWN) deltaHeight = -deltaHeight;

        this.currentWidth  = Math.max(300, this.startWidth + deltaWidth);
        this.currentHeight = Math.max(300, this.startHeight + deltaHeight);
        this.currentWidth  = Math.min(800, this.currentWidth);
        this.currentHeight = Math.min(800, this.currentHeight);
        if(this.state.callback) this.state.callback(this.currentWidth, this.currentHeight);

        // console.log('w:'+this.startWidth, this.startWidth + deltaWidth, this.state.width);
    }

    stopDrag = () => {
        if (this.state.dragging) {
            this.setState({ dragging: false });
            this.startWidth = this.currentWidth;
            this.startHeight = this.currentHeight;
            // console.log('--- w:'+this.startWidth, this.state.width);
        }
    }


    componentDidMount() {
        document.addEventListener('mousemove', this.onDrag);
        document.addEventListener('mouseup', this.stopDrag);
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.onDrag);
        document.removeEventListener('mouseup', this.stopDrag);
    }

    render(): JSX.Element {
        let layout: JSX.Element = <></>;
        layout = (<>
            {/* Left Panel */}
            <div className={'handle'} onMouseDown={this.startDrag}></div>
        </>);
        return layout;
    }
}
