import React from 'react';
import AbstractReactComponent from "../../core/view/AbstractReactComponent";

interface COMM_PROPS{
}
export default class AuthSuccess extends AbstractReactComponent<any> {
    state:COMM_PROPS = {};

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {};
        AbstractReactComponent.pullups(prevState, nextProps, p, );
        return p;
    }

    onClick(){
        window.self.close();
    }

    render(): JSX.Element {
        return (
            <>
                <div className={'google-auth'}>
                    인증성공
                </div>
                <div className={'btn-set dual'}>
                    <button className={'btn btn-purple btn-ico2 scaled-el'} onClick={()=>this.onClick() }>닫기</button>
                </div>
            </>
        );
    }
}
