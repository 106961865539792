import React from 'react';
import AbstractReactComponent from "../../core/view/AbstractReactComponent";
import Main from "../Main";


/**
 * 프로젝트 중앙 쿼리창
 */
export default class MainFooter extends AbstractReactComponent<Main> {

    tabClick(idx: number): void {

    }

    render(): JSX.Element {
        let layout: JSX.Element = <></>;
        layout = (<>
            {/* footer */}
            <footer>footer</footer>
        </>);
        return layout;
    }
}
