import {MutableRefObject} from "react";

export interface REF_OBJECTS{
    [key:string]:MutableRefObject<any>
}


export enum EVENT{
    TRANS_REQ,
    TRANS_RES,
    RELOAD,
    ON,
    ERROR,
    CONFIG_RELOAD,
    POPUP_CALLBACK,
    SERVICE_CALL,
    SERVICE_END,

    OPEN_SETTING,
}
