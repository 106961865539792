import React from 'react';
import AbstractReactComponent from "../../core/view/AbstractReactComponent";
import Main from "../Main";
import {getDefaultWinConfig, hideAllProjectExplorer, WIN_CONFIG, windowConfig} from "../define/types";
import {EVENT} from "../../core/define/types";


interface COMM_PROPS{
    config:WIN_CONFIG,
}
/**
 * 프로젝트 왼쪽 프로젝트 Explorer, DB Explorer 창
 */
export default class MainLeftToggle extends AbstractReactComponent<Main> {
    constructor(props:any) {
        super(props);
        this.state = {config:getDefaultWinConfig()};
        // this.init();
    }

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {config:getDefaultWinConfig()};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'config');
        return p;
    }

    on(event:EVENT, data:any, spread?:boolean){
        super.on(event, data, spread);
        if(event === EVENT.CONFIG_RELOAD) {
            try{
                this.setState({config: windowConfig});
            }catch(e){
                console.log('Config not found in props');
            }
        }
    }

    toggleClick(type:string){
        if(type === 'project'){
            let isShow = !windowConfig.PROJECT_EXPLORE.SHOW;
            if(isShow) hideAllProjectExplorer();
            windowConfig.PROJECT_EXPLORE.SHOW = isShow;
        }else{
            let isShow = !windowConfig.PROJECT_DB_EXPLORE.SHOW;
            if(isShow) hideAllProjectExplorer();
            windowConfig.PROJECT_DB_EXPLORE.SHOW = isShow;
        }
        this.setState({config: windowConfig});
        this.getRootContext().broadcast(EVENT.CONFIG_RELOAD);
    }

    render(): JSX.Element {
        let layout: JSX.Element = <></>;

        let statusCss1 = '';
        let statusCss2 = '';
        if(windowConfig.PROJECT_EXPLORE.SHOW) statusCss1 += ' on';
        if(windowConfig.PROJECT_DB_EXPLORE.SHOW) statusCss2 += ' on';
        layout = (<>
            {/* Left Panel */}
            <aside className="panel-toogle left">
                <div>
                    <button id="project-toggle" className={`${statusCss1}`} onClick={()=>this.toggleClick('project')}><span>Project</span></button>
                    <button id="dbConnect-toggle" className={`${statusCss2}`} onClick={()=>this.toggleClick('db-connection')}><span>Database</span></button>
                </div>
            </aside>
        </>);
        return layout;
    }
}
