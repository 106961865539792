import React from 'react';
import AbstractReactComponent from "../../core/view/AbstractReactComponent";
import Main from "../Main";
import {APP_MENUS, MENU} from "../define/Menus";
import {bsp} from "../api/BIServiceMap";


/**
 * 프로젝트 좌측 얇은 탭
 */
export default class MainHeader extends AbstractReactComponent<Main> {

    clickMenu(menu:MENU): void {
        console.log(menu);
        if(menu!= null) bsp.run(menu.SERVICE, null);
    }

    getMenusJSX(menu?:MENU[], isRoot:boolean = false){
        let jsx: JSX.Element[] = [];
        if(menu != null && menu.length > 0) {
            menu?.forEach((menu:MENU) => {
                let inner = <></>;
                if ((menu.TYPE === 'MENU' || menu.TYPE === 'HEAD') && menu.SHOW) {
                    let shortcut = <></>;
                    if(menu.SHORTCUT != null) {
                        shortcut = <span className="shortcut">{menu.SHORTCUT}</span>;
                    }else{
                        shortcut = <span className="shortcut">&nbsp;</span>;
                    }
                    let inner = <button onClick={() => this.clickMenu(menu)}><span>{menu.NAME}</span>{shortcut}</button>
                    if (menu.CHILD != null && menu.CHILD.length > 0) {
                        inner = (<>
                            <button onClick={() => this.clickMenu(menu)}><span>{menu.NAME}</span></button>
                            {this.getMenusJSX(menu.CHILD)}
                        </>);
                    }
                    inner = <li key={menu.NAME}>{inner}</li>;
                    jsx.push(inner);
                } else if (menu.TYPE === 'DIVIDE') {
                    jsx.push(<li key={menu.NAME} className="divide"></li>);
                }
            });
        }
        if(isRoot){
            return <menu>{jsx}</menu>
        }
        return <ul className={'menu'}>{jsx}</ul>
    }

    render(): JSX.Element {
        let layout: JSX.Element = <></>;
        layout = (<>
            {/* header */}
            <header>
                <h1><img src={require('../../../styles/img/logo.png')} alt="범익로고" />AI Query Builder</h1>

                {/* Menu */}
                {this.getMenusJSX(APP_MENUS, true)}

                {/* Dark Mode Toggle */}
                <label className="switch">
                    <span>Dark Mode</span>
                    <input type="checkbox" id="btn-dark-toggle" checked />
                    <div className="slider round"></div>
                </label>
            </header>
        </>);
        return layout;
    }
}
