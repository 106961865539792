import React from 'react';
import AbstractReactComponent from "../../../core/view/AbstractReactComponent";
import {getDefaultWinConfig, WIN_CONFIG, WORK_DATA} from "../../define/types";
import Main from "../../Main";


interface COMM_PROPS{
    config:WIN_CONFIG,
}
/**
 * 프로젝트 컨텐츠 쿼리 탭
 */
export default class ContentTabs extends AbstractReactComponent<Main> {

    constructor(props:any) {
        super(props);
        this.state = {config:getDefaultWinConfig()};
        // this.init();
    }

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {config:getDefaultWinConfig()};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'config');
        return p;
    }

    getWorkTabJSX(): JSX.Element {
        let jsx: JSX.Element[] = [];
        if(this.state.config.CONTENT && this.state.config.CONTENT.TABS.length > 0){
            this.state.config.CONTENT.TABS.map((tab:WORK_DATA, idx:number)=>{
                jsx.push(<div aria-label={tab.SEQ} className="icon ico-qry" key={idx}><span>{tab.WORK_NAME}</span><span
                    className="btn-close"></span></div>)
            });
        }
        return <>{jsx}</>
    }

    render(): JSX.Element {
        let layout: JSX.Element = <></>;
        layout = (<>
            <div className="panel-tabMenus">
                {this.getWorkTabJSX()}
            </div>
        </>);
        return layout;
    }
}
