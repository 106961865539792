import React from 'react';
import AbstractReactComponent from "../../../core/view/AbstractReactComponent";
import {
    getDefaultWinConfig,
    hideAllProjectExplorer,
    PROJECT_FILE_INFO, PROJECT_INFO,
    WIN_CONFIG,
    windowConfig
} from "../../define/types";
import {bsp, SERVICE_MAP} from "../../api/BIServiceMap";
import {EVENT} from "../../../core/define/types";
import DragHandler from "../drag/DragHandler";


interface COMM_PROPS{
    config:WIN_CONFIG,
    projects:PROJECT_INFO[],    //전체 프로젝트
    selected?:PROJECT_INFO,     // 선택된 프로젝트
}
/**
 * 프로젝트 중앙 쿼리창
 */
export default class ProjectExplore extends AbstractReactComponent<any> {

    constructor(props:any) {
        super(props);
        this.state = {projects:[], config:getDefaultWinConfig()};
        // this.init();
    }

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {projects:[], config:getDefaultWinConfig()};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'projects', 'selected', 'config');
        return p;
    }

    on(event:EVENT, data:any, spread?:boolean){
        super.on(event, data, spread);
        if(event === EVENT.CONFIG_RELOAD) {
            try{
                this.setState({config: windowConfig});
            }catch(e){
                console.log('Config not found in props');
            }
        }
    }

    init():void{
        //로딩 시작
        this.getResponse('/CFG/CFG010001', {}, {
            success:(res:any, data:any)=>{
                // session.setSession(result);
                console.log(res, data);
            }
        });
    }

    createProject():void {
        bsp.run(SERVICE_MAP.PROJECT_NEW, null);
    }

    closeProject(isShow:boolean):void {
        if(isShow) hideAllProjectExplorer();
        windowConfig.PROJECT_EXPLORE.SHOW = isShow;
        this.setState({config: windowConfig});
        this.getRootContext().broadcast(EVENT.CONFIG_RELOAD);
    }

    getProjectChildJSX(files:PROJECT_FILE_INFO[]):JSX.Element {
        let layout: JSX.Element = <></>;

        if(files != null && files.length > 0) {
            layout = (<>
                <ul>
                    {files.map((file:PROJECT_FILE_INFO, idx:number)=>{
                        return (<li key={idx}>
                            <div className="tree-list-row">
                                <div className="tree-row">
                                    <div className="tree-row-indent"></div>
                                    <div className="tree-row-twistie collapsible"></div>
                                    <div className="tree-row-contents icon ico-folder">
                                        <div className="label-container">
                                            <span>{file.FILE_NAME}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>);
                    })}
                </ul>
            </>);
        }else{
            layout = (<></>);
        }
        return layout;
    }

    getProjectJSX():JSX.Element {
        let layout: JSX.Element = <></>;
        if(this.state.projects.length === 0) {
            layout = (<div className="tree-list show-file-icons mouse-support">
                <div className={'no-project'}><a onClick={()=>this.createProject()}>New Project</a></div>
            </div>);
        } else {
            layout = (<>
            <div className="tree-list show-file-icons mouse-support">
                    <ul className="tree-list-rows">
                        {this.state.projects.map((project:PROJECT_INFO, idx:number)=>{
                            return (<li key={idx}>
                                <div className="tree-list-row">
                                    <div className="tree-row">
                                        <div className="tree-row-indent"></div>
                                        <div className="tree-row-twistie collapsible"></div>
                                        <div className="tree-row-contents icon ico-workspace">
                                            <div className="label-container">
                                                <span>{project.PROJECT_NAME}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.getProjectChildJSX(project.CHILDS)}
                            </li>);
                        })}
                    </ul>
                </div>
            </>);
        }
        return layout;
    }

    dragCallback = (width:number, height:number):void => {
        windowConfig.PROJECT_EXPLORE.WIDTH = width;
        this.setState({config: windowConfig});
    }

    render(): JSX.Element {
        let layout: JSX.Element = <></>;

        let statusCss = '';
        if(windowConfig.PROJECT_EXPLORE.SHOW) statusCss += ' on';
        /*
          * tree-list 옵션
          * ellipsis : 밀 줄임
          * show-file-icons : 파일 및 폴더 icon
          * mouse-support : cursor pointer
          * tree-row-indent : 세로구분 라인 - (depth - 1) X "<div className="indent-guide"></div>"
          * tree-row-twistie : 폴더 접기/펼치기 아이콘 - style="padding-left:(depth-1) * 8px"
          * tree-row-contents: icon 필수
        */
        layout = (<>
            {/* Project */}
            <article id="project-panel" className={`panel left ${statusCss}`} style={{ width: this.state.config.PROJECT_EXPLORE.WIDTH }}>
                <div className="panel-bar">
                    <h2>Project</h2>
                    <div>
                        <button className="btn-close" onClick={()=>this.closeProject(false)}>close</button>
                    </div>
                </div>
                <div className="panel-body">
                    {
                        /* file tree explorer */
                        this.getProjectJSX()
                    }
                </div>
                <DragHandler context={this} width={this.state.config.PROJECT_EXPLORE.WIDTH} callback={this.dragCallback}></DragHandler>
            </article>
        </>);
        return layout;
    }
}
