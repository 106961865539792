import AbstractReactComponent from "../../core/view/AbstractReactComponent";
import {FETCH_EVENT} from "../../core/transaction/Transaction";
import PROJECT_NEW from "./worker/PROJECT_NEW";
import BIServiceWorker from "./BIServiceWorker";
import PROJECT_REMOVE from "./worker/PROJECT_REMOVE";
import PROJECT_EXPORT from "./worker/PROJECT_EXPORT";
import {EVENT} from "../../core/define/types";
import NONE from "./worker/NONE";
import ABOUT_BITTS from "./worker/ABOUT_BITTS";
import SETTING from "./worker/SETTING";
import CLOSE_BITTS from "./worker/CLOSE_BITTS";
import PROJECT_CLOSE from "./worker/PROJECT_CLOSE";
import LICENSE from "./worker/LICENSE";
import HELP from "./worker/HELP";
import HELP_START from "./worker/HELP_START";
import SHORT_CUT from "./worker/SHORT_CUT";
import DB_EXPORT from "./worker/DB_EXPORT";
import DB_CLOSE from "./worker/DB_CLOSE";
import DB_REMOVE from "./worker/DB_REMOVE";
import DB_NEW from "./worker/DB_NEW";

//프로그램에서 사용하는 공용 서비스 목록
export enum SERVICE_MAP {
    NONE,
    ABOUT_BITTS,
    SETTING,
    CLOSE_BITTS,
    LICENSE,
    PROJECT_NEW,
    PROJECT_REMOVE,
    PROJECT_EXPORT,
    PROJECT_CLOSE,
    DB_NEW,
    DB_REMOVE,
    DB_EXPORT,
    DB_CLOSE,
    HELP,
    HELP_START,
    SHORT_CUT,
}

//서비스 목록에 따른 서비스 클래스 매핑
const ClassMap: { [key in SERVICE_MAP]: typeof BIServiceWorker } = {
    [SERVICE_MAP.NONE]: NONE,
    [SERVICE_MAP.ABOUT_BITTS]: ABOUT_BITTS,
    [SERVICE_MAP.SETTING]: SETTING,
    [SERVICE_MAP.CLOSE_BITTS]: CLOSE_BITTS,
    [SERVICE_MAP.LICENSE]: LICENSE,
    [SERVICE_MAP.PROJECT_NEW]: PROJECT_NEW,
    [SERVICE_MAP.PROJECT_REMOVE]: PROJECT_REMOVE,
    [SERVICE_MAP.PROJECT_EXPORT]: PROJECT_EXPORT,
    [SERVICE_MAP.PROJECT_CLOSE]: PROJECT_CLOSE,
    [SERVICE_MAP.DB_NEW]: DB_NEW,
    [SERVICE_MAP.DB_REMOVE]: DB_REMOVE,
    [SERVICE_MAP.DB_EXPORT]: DB_EXPORT,
    [SERVICE_MAP.DB_CLOSE]: DB_CLOSE,
    [SERVICE_MAP.HELP]: HELP,
    [SERVICE_MAP.HELP_START]: HELP_START,
    [SERVICE_MAP.SHORT_CUT]: SHORT_CUT,
};

export class BIServiceProvider {
    rootContext?: AbstractReactComponent<any>;
    allServiceInstances: { [key in SERVICE_MAP]?: BIServiceWorker } = {};

    constructor(rootContext?: AbstractReactComponent<any>) {
        this.rootContext = rootContext;
        this.allServiceInstances = this.createInstancesForAllServices();
    }

    setRootContext(rootContext: AbstractReactComponent<any>) {
        this.rootContext = rootContext;
    }

    //구현된 모든 서비스 인스턴스를 생성
    createInstancesForAllServices(): { [key in SERVICE_MAP]?: BIServiceWorker } {
        const instances: { [key in SERVICE_MAP]?: BIServiceWorker } = {};
        for (const key in SERVICE_MAP) {
            if (isNaN(Number(key))) { // 키가 숫자가 아닌 경우에만 인스턴스 생성
                const service = SERVICE_MAP[key as keyof typeof SERVICE_MAP];
                const instance = this.createServiceInstance(service, this);
                instances[service] = instance;
            }
        }

        return instances;
    }

    //서비스 인스턴스 생성
    createServiceInstance(service: SERVICE_MAP, ...args: any[]): BIServiceWorker {
        const ServiceClass = ClassMap[service];
        if (ServiceClass) {
            return new ServiceClass(...args);
        }
        return new BIServiceWorker();
    }

    //서비스 인스턴스 호출
    run(service:SERVICE_MAP, param: any, callback?: FETCH_EVENT): void {
        this.rootContext?.broadcast(EVENT.SERVICE_CALL, {service:service, param:param});
        if (this.allServiceInstances[service]) {
            this.allServiceInstances[service]?.run(param, callback);
        }
        this.rootContext?.broadcast(EVENT.SERVICE_END, {service:service, param:param});
    }
}

export const bsp = new BIServiceProvider();