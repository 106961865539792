import BIServiceWorker from "../BIServiceWorker";
import {FETCH_EVENT} from "../../../core/transaction/Transaction";


export default class CLOSE_BITTS extends BIServiceWorker {

    run(param:any, callback?:FETCH_EVENT): void {
        console.log('CLOSE_BITTS run', param, callback);
        window.document.location.href = '/';
    }
}

