// =============== 사용자 정보 ===============
export const GOOGLE_AUTH_SCOPE = [
    'https://www.googleapis.com/auth/contacts.readonly',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/user.birthday.read',
];
enum LICENSE_TYPE {
    FREE,       // 무료
    TRIAL,      // 체험판
    BASIC,      // 기본
    PREMIUM,    // 프리미엄
}
export interface USER_INFO {
    ID: string,             // 사용자 아이디
    NAME: string,           // 사용자 이름
    EMAIL: string,          // 사용자 이메일
    ACCESS_TOKEN: string,   // 접근 토큰
}
export interface LICENSE_INFO {
    LICENSE: string,    // 라이센스 키
    LICENSE_TYPE: LICENSE_TYPE, // 라이센스 타입
    LICENSE_TYPE_NAME: string,  // 라이센스 타입명
    USER_COUNT: string,         // 사용자 수
}

// =============== //사용자 정보 ===============


// =============== Project Explore ===============
enum FILE_TYPE {
    SQL,    // SQL
    WORK,    // 워크스페이스 파일
    TXT,  // 텍스트
    EXCEL,  // 엑셀
    CSV,    // CSV
    CONNECTION,    // DB 커넥션 정보
}
export interface PROJECT_INFO {
    SEQ: string,                // 프로젝트 시퀀스
    PROJECT_NAME: string,       // 프로젝트 이름
    PROJECT_DESC: string,       // 프로젝트 설명
    CHILDS: PROJECT_FILE_INFO[]
}
export interface PROJECT_FILE_INFO {
    FILE_PATH: string,       // 파일경로
    FILE_NAME: string,       // 파일명
    FILE_TYPE: FILE_TYPE,       // 파일타입(확장자)
    IS_COLLAPSE?: boolean,          // 펼침 여부
    CHILDS: PROJECT_FILE_INFO[],  // 하위 파일
}
// =============== //Project Explore ===============

// =============== Project DB Explore ===============
enum DBMS_TYPE {
    ORACLE,
    MYSQL,
    MARIADB,
    POSTGRESQL,
    MSSQL,
}
export enum DB_NODE_TYPE {
    TABLE,      // 테이블
    FOLDER,     // 폴더그룹
    COLUMN,     // 컬럼
    PK_COLUMN,     // 컬럼
    SCHEMA,     // 스키마
    ROUTINE,    // 함수
    SEQUENCE,   // 시퀀스
}
export interface DB_CONNECTION {
    SEQ: string,                // 커넥션 식별자
    DBMS: DBMS_TYPE,       //DBMS 타입
    CONN_NAME: string,       //커넥션
    CONN_DESC: string,       //주석
    HOST: string,           //호스트
    PORT: number,           //포트
    SID: string,            //SID
    USER_NAME: string,      //사용자
    PASSWORD: string,       //비밀번호
    USE_YN: string,         //사용여부
    URL: string,            //URL
}
export interface DB_TABLE {
    TABLE_NAME: string,         // 테이블 이름
    TABLE_COMMENT: string,      // 테이블 설명
    COLUMN_LIST: DB_COLUMN[],   // 테이블 컬럼 리스트
}
export interface DB_COLUMN {
    COLUMN_NAME: string,        // 컬럼 이름
    COLUMN_COMMENT: string,     // 컬럼 설명
    DATA_TYPE: string,          // 컬럼 타입
    DATA_LENGTH: number,        // 컬럼 길이
    DATA_PRECISION: number,     // 컬럼 정밀도
    DATA_SCALE: number,         // 컬럼 스케일
    NULLABLE: string,           // 컬럼 NULL 여부
    COLUMN_ID: number,          // 컬럼 ID
    PK_YN: string,              // 컬럼 PK 여부
    REG_DATE: string,           // 컬럼 등록일
}
export interface DB_NODE_INFO {
    DB_NODE_TYPE: DB_NODE_TYPE,         // DB 노드종류
    DB_NODE_NAME: string,       // 프로젝트 이름
    DB_NODE_DESC: string,       // 프로젝트 설명
    IS_COLLAPSE?: boolean,          // 펼침 여부
    CHILDS: DB_NODE_INFO[],    // 하위노드
}
export interface PROJECT_DB_INFO {
    SEQ: string,                        // DB 시퀀스
    DB_CONNECTION: DB_CONNECTION,          // 노드정보
    CHILDS: DB_NODE_INFO[],    // 하위노드
}
// =============== //Project DB Explore ===============


// =============== Workspace Assistant ===============

export enum ANSWER_TYPE {
    SQL,
    TEXT,
    JSON,
    CODE,
}

// ASSISTANT 정보
export interface ASSISTANT_DATA {
    SEQ: string,                    // 작업 시퀀스
    QUESTION: string,              // 질문
    ANSWER: string,              // 작업 설명
    ANSWER_TYPE: ANSWER_TYPE,           // 작업 내용
    MODEL: string,              // 사용된 모델
}
export interface ASSISTANT_INFO {
    SEQ: string,                        // ASSISTANT 시퀀스
    CHILDS: ASSISTANT_DATA[],    // 하위노드
}
// =============== //Workspace Assistant ===============

// =============== Workspace Explore ===============
//  작업정보
export interface WORK_DATA {
    SEQ: string,                    // 작업 시퀀스
    WORK_NAME: string,              // 작업 이름
    WORK_DESC: string,              // 작업 설명
    WORK_CONTENT: string,           // 작업 내용
    WORK_STATUS: string,            // 작업 상태
    IS_COLLAPSE?: boolean,          // 펼침 여부
    QUERY_DATA?: QUERY_DATA,        // 쿼리 리스트
    CHILDS?: WORK_DATA[],            // 하위노드
}
export interface WORKSPACE_INFO {
    SEQ: string,                        // DB 시퀀스
    CHILDS: WORK_DATA[],    // 하위노드
}
// =============== //Workspace Explore ===============



// =============== Window Configuration ===============
export interface WIN_CONFIG {
    PROJECT_EXPLORE: {
        SHOW: boolean,  // 프로젝트 탐색창 표시 여부
        WIDTH: number,  // 프로젝트 탐색창 너비
    },
    PROJECT_DB_EXPLORE: {
        SHOW: boolean,  // 프로젝트 DB 탐색창 표시 여부
        WIDTH: number,  // 프로젝트 DB 탐색창 너비
    },
    WORKSPACE_EXPLORE: {
        SHOW: boolean,  // 워크스패이스 작업창 표시 여부
        WIDTH: number,  // 워크스패이스 작업창 너비
    },
    WORKSPACE_ASSISTANT: {
        SHOW: boolean,  // AI 어시스턴트 작업창 표시 여부
        WIDTH: number,  // AI 어시스턴트 작업창 너비
    },
    WORKSPACE_SETTING: {
        SHOW: boolean,  // 프로젝트셋팅 표시 여부
        WIDTH: number,  // 프로젝트셋팅 탐색창 너비
    },
    CONTENT: {
        TABS:WORK_DATA[]
    }
}

export function getDefaultWinConfig(): WIN_CONFIG {
    return {
        PROJECT_EXPLORE: {
            SHOW: true, WIDTH:300
        },
        PROJECT_DB_EXPLORE: {
            SHOW: false, WIDTH:300
        },
        WORKSPACE_EXPLORE: {
            SHOW: false, WIDTH:300
        },
        WORKSPACE_ASSISTANT: {
            SHOW: false, WIDTH:300
        },
        WORKSPACE_SETTING: {
            SHOW: false, WIDTH:300
        },
        CONTENT: {
            TABS:[]
        }
    }
}
export let windowConfig: WIN_CONFIG = getDefaultWinConfig();
export function setWindowConfig(config: WIN_CONFIG) {
    if(config) Object.assign(windowConfig, config);
}
export function hideAllProjectExplorer() {
    windowConfig.PROJECT_EXPLORE.SHOW = false;
    windowConfig.PROJECT_DB_EXPLORE.SHOW = false;
}
export function hideAllRightProjectExplorer() {
    windowConfig.WORKSPACE_EXPLORE.SHOW = false;
    windowConfig.WORKSPACE_ASSISTANT.SHOW = false;
    windowConfig.WORKSPACE_SETTING.SHOW = false;
}

// =============== //Window Configuration ===============


// =============== SQL Builder ===============
enum CREATE_TYPE {
    AI,     // AI
    USER,   // 사람이 작성
}
export interface QUERY_DATA {
    SEQ: string,                // 쿼리 시퀀스
    QUERY_CONTENT: string,      // 쿼리 내용
    CREATE_TYPE: CREATE_TYPE,   // 생성 타입
    MOD_DATE: string,           // 수정일
}
// >>> //작업 정보


// =============== //SQL Builder ===============
