import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {FirebaseApp, FirebaseOptions} from "@firebase/app";
import {Analytics} from "@firebase/analytics";

export default abstract class FireBaseCertifier {
    firebaseConfig:FirebaseOptions = {
        apiKey: "AIzaSyDIXeTAcjsryCdU_zyIClu7XWCNBdLbTVU",
        authDomain: "bitts-426005.firebaseapp.com",
        projectId: "bitts-426005",
        storageBucket: "bitts-426005.appspot.com",
        messagingSenderId: "782405275950",
        appId: "1:782405275950:web:cc5835489d34bb88912abf",
        measurementId: "G-KV4ZSZ6NYH"
    };
    app: FirebaseApp;
    analytics: Analytics;

    constructor() {
        this.app = initializeApp(this.firebaseConfig);
        this.analytics = getAnalytics(this.app);
    }
}