import {FETCH_EVENT} from "../../core/transaction/Transaction";
import {BIServiceProvider} from "./BIServiceMap";


export default class BIServiceWorker {
    protected provider?:BIServiceProvider;
    constructor(provider?: BIServiceProvider) {
        this.provider = provider;
    }

    transaction(){
        return this.provider?.rootContext?.transaction;
    }

    getContext(){
        return this.provider?.rootContext;
    }

    run(param:any, callback?:FETCH_EVENT): void {
        console.log('Application service run()');
    }
}
