import React from 'react';
import AbstractReactComponent from "../../core/view/AbstractReactComponent";
import Main from "../Main";
import {
    getDefaultWinConfig,
    hideAllProjectExplorer,
    hideAllRightProjectExplorer,
    WIN_CONFIG,
    windowConfig
} from "../define/types";
import {EVENT} from "../../core/define/types";


interface COMM_PROPS{
    config:WIN_CONFIG,
}
/**
 * 프로젝트 중앙 쿼리창
 */
export default class MainRightToggle extends AbstractReactComponent<Main> {
    constructor(props:any) {
        super(props);
        this.state = {config:getDefaultWinConfig()};
        // this.init();
    }

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {config:getDefaultWinConfig()};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'config');
        return p;
    }

    on(event:EVENT, data:any, spread?:boolean){
        super.on(event, data, spread);
        if(event === EVENT.CONFIG_RELOAD) {
            try{
                this.setState({config: windowConfig});
            }catch(e){
                console.log('Config not found in props');
            }
        }else if(event === EVENT.OPEN_SETTING) {
            this.toggleClick('setting', true);
        }
    }

    toggleClick(type:string, show?:boolean){
        if(type === 'workspace'){
            let isShow = !windowConfig.WORKSPACE_EXPLORE.SHOW;
            if(show !== undefined) isShow = show;
            if(isShow) hideAllRightProjectExplorer();
            windowConfig.WORKSPACE_EXPLORE.SHOW = isShow;
        }else if(type === 'assistant'){
            let isShow = !windowConfig.WORKSPACE_ASSISTANT.SHOW;
            if(show !== undefined) isShow = show;
            if(isShow) hideAllRightProjectExplorer();
            windowConfig.WORKSPACE_ASSISTANT.SHOW = isShow;
        }else if(type === 'setting'){
            let isShow = !windowConfig.WORKSPACE_SETTING.SHOW;
            if(show !== undefined) isShow = show;
            if(isShow) hideAllRightProjectExplorer();
            windowConfig.WORKSPACE_SETTING.SHOW = isShow;
        }
        this.setState({config: windowConfig});
        this.getRootContext().broadcast(EVENT.CONFIG_RELOAD);
    }

    render(): JSX.Element {
        let layout: JSX.Element = <></>;
        let statusCss1 = '';
        let statusCss2 = '';
        let statusCss3 = '';
        if(windowConfig.WORKSPACE_ASSISTANT.SHOW) statusCss1 += ' on';
        if(windowConfig.WORKSPACE_EXPLORE.SHOW) statusCss2 += ' on';
        if(windowConfig.WORKSPACE_SETTING.SHOW) statusCss3 += ' on';
        layout = (<>
            {/* Right Panel */}
            <aside className="panel-toogle right">
                <div>
                    <button id="ai-assistant-toggle" className={`${statusCss1}`} onClick={()=>this.toggleClick('assistant')}><span>AI Assistant</span></button>
                    <button id="workspace-toggle" className={`${statusCss2}`} onClick={()=>this.toggleClick('workspace')}><span>Workspace</span></button>
                    <button id="setting-toggle" className={`${statusCss3}`} onClick={()=>this.toggleClick('setting')}><span>Setting</span></button>
                </div>
            </aside>
        </>);
        return layout;
    }
}
