import BIServiceWorker from "../BIServiceWorker";
import {FETCH_EVENT} from "../../../core/transaction/Transaction";


export default class LICENSE extends BIServiceWorker {

    run(param:any, callback?:FETCH_EVENT): void {
        console.log('LICENSE run', param, callback);
    }
}

