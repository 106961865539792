import React from 'react';
import AbstractReactComponent from "../../../core/view/AbstractReactComponent";
import Main from "../../Main";
import {getDefaultWinConfig, hideAllProjectExplorer, WIN_CONFIG, windowConfig, WORK_DATA} from "../../define/types";
import {EVENT} from "../../../core/define/types";
import {bsp, SERVICE_MAP} from "../../api/BIServiceMap";
import DragHandler, {DRAG_DIRECTION} from "../drag/DragHandler";


interface COMM_PROPS{
    config:WIN_CONFIG,
}
/**
 * 우측 워크스페이스 탭
 */
export default class WorkspaceSetting extends AbstractReactComponent<Main> {

    constructor(props:any) {
        super(props);
        this.state = {projects:[], config:getDefaultWinConfig()};
        // this.init();
    }

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {config:getDefaultWinConfig()};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'config');
        return p;
    }

    on(event:EVENT, data:any, spread?:boolean){
        super.on(event, data, spread);
        if(event === EVENT.CONFIG_RELOAD) {
            try{
                this.setState({config: windowConfig});
            }catch(e){
                console.log('Config not found in props');
            }
        }
    }

    createProject():void {
        bsp.run(SERVICE_MAP.DB_NEW, null);
    }

    closeProject(isShow:boolean):void {
        if(isShow) hideAllProjectExplorer();
        windowConfig.WORKSPACE_SETTING.SHOW = isShow;
        this.setState({config: windowConfig});
        this.getRootContext().broadcast(EVENT.CONFIG_RELOAD);
    }

    getProjectJSX():JSX.Element {
        let layout: JSX.Element = <></>;
        if(this.state.projects.length === 0) {
            layout = (<div className="tree-list show-file-icons mouse-support">
                <div className={'no-project'}><a onClick={()=>this.createProject()}>Setting</a></div>
            </div>);
        } else {
            layout = (<>
                <div className="tree-list show-file-icons mouse-support">
                    Configration
                </div>
            </>);
        }
        return layout;
    }

    dragCallback = (width:number, height:number):void => {
        windowConfig.WORKSPACE_SETTING.WIDTH = width;
        this.setState({config: windowConfig});
    }

    render(): JSX.Element {
        let layout: JSX.Element = <></>;

        let statusCss = '';
        if(windowConfig.WORKSPACE_SETTING.SHOW) statusCss += ' on';

        layout = (<>
            {/* workspace */}
            <article id="setting-panel" className={`panel right ${statusCss}`} style={{ width: this.state.config.WORKSPACE_SETTING.WIDTH }}>
                <div className="panel-bar">
                    <h2>Setting</h2>
                    <div>
                        <button className="btn-close" onClick={()=>this.closeProject(false)}>close</button>
                    </div>
                </div>

                <div className="panel-body">
                    {
                        /* file tree explorer */
                        this.getProjectJSX()
                    }
                </div>
                <DragHandler context={this} width={this.state.config.WORKSPACE_SETTING.WIDTH} callback={this.dragCallback} directionX={DRAG_DIRECTION.HORIZONTAL_LEFT}></DragHandler>
            </article>

        </>);
        return layout;
    }
}
