import BIServiceWorker from "../BIServiceWorker";
import {FETCH_EVENT} from "../../../core/transaction/Transaction";


export default class HELP_START extends BIServiceWorker {

    run(param:any, callback?:FETCH_EVENT): void {
        console.log('HELP_START run', param, callback);
    }
}

