import BIServiceWorker from "../BIServiceWorker";
import {FETCH_EVENT} from "../../../core/transaction/Transaction";


export default class ABOUT_BITTS extends BIServiceWorker {

    run(param:any, callback?:FETCH_EVENT): void {
        console.log('ABOUT_BITTS run', param, callback);
    }
}

