import {GoogleAuthProvider, signInWithPopup, getAuth} from "firebase/auth";
import FireBaseCertifier from "./FireBaseCertifier";
import {GOOGLE_AUTH_SCOPE} from "../define/types";
import {EVENT} from "../../core/define/types";
import {OAuthCredential, User} from "@firebase/auth";
import Transaction from "../../core/transaction/Transaction";
import axios from "axios/index";
import {DEFAULT_CONFIG} from "../../core/config/DefaultConfig";

export interface GoogleCredential{
    credential:OAuthCredential,
    user:User
}
export default class GoogleCertifier extends FireBaseCertifier{
    transaction:Transaction = Transaction.with();
    provider:GoogleAuthProvider = new GoogleAuthProvider();
    credential?:GoogleCredential;

    CLIENT_ID = '782405275950-91nd1e3eu95bh8trgs5d5hag4lue3pi4.apps.googleusercontent.com';
    // REDIRECT_URL = 'http://localhost:53001/google/api/service/jwt/jwtcallback';
    REDIRECT_URL = 'https://api.bi-tts.co.kr/google/api/service/jwt/jwtcallback';
    // FRONT = 'http://localhost:53000/Main';
    FRONT = 'https://www.bi-tts.co.kr/Main';
    // FROMT = 'https://www.bitts.co.kr/google/success'; // 기존 경로

    constructor() {
        super();
        GOOGLE_AUTH_SCOPE.forEach(scope => this.provider.addScope(scope));
    }

    /**
     * 구글 로그인 인증팝업&로그인
     * @param callback
     */
    signIn(callback:Function){
        if (this.credential) {
            if(callback) callback(EVENT.TRANS_RES, this.credential);
        }else{
            const auth = getAuth();
            signInWithPopup(auth, this.provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                if (credential) {
                    this.credential = {
                        credential:credential,
                        user:result.user
                    }
                    if(callback) callback(EVENT.TRANS_RES, this.credential);
                }
            }).catch((error) => {
                console.log(error);
                if(callback) callback(EVENT.ERROR, error);
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
        }
    }

    /**
     * 구글링크를 통한 로그인
     * @param callback
     */
    signJwtIn(callback:Function) {
        let authLink = `https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20email%20profile&`;
        authLink += `response_type=code&`;
        authLink += `access_type=offline&`;
        authLink += `redirect_uri=${this.REDIRECT_URL}&`;
        authLink += `client_id=${this.CLIENT_ID}&`;
        authLink += `state=${encodeURIComponent(`front=${this.FRONT}`)}`;
        const newWindow = window.open(authLink, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
        console.log(authLink);
    }
}
export const googleCertifier = new GoogleCertifier();