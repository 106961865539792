import BIServiceWorker from "../BIServiceWorker";
import {FETCH_EVENT} from "../../../core/transaction/Transaction";


export default class DB_REMOVE extends BIServiceWorker {

    run(param:any, callback?:FETCH_EVENT): void {
        console.log('DB_REMOVE run()', param);
    }
}

