import React from 'react';
import AbstractReactComponent from "../../../core/view/AbstractReactComponent";
import {getDefaultWinConfig, WIN_CONFIG, WORK_DATA} from "../../define/types";
import Main from "../../Main";



interface COMM_PROPS{
    config:WIN_CONFIG, target?:WORK_DATA
}
/**
 * 프로젝트 중앙 쿼리창
 */
export default class ContentBody extends AbstractReactComponent<Main> {

    constructor(props:any) {
        super(props);
        this.state = {config:getDefaultWinConfig()};
        // this.init();
    }

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {config:getDefaultWinConfig()};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'config', 'target');
        return p;
    }

    getWorkTabJSX(): JSX.Element {
        let jsx: JSX.Element = <></>;
        if(this.state.target){
            jsx = (<div id={'content_'+this.state.target.SEQ} className="on" key={'content-body-'+this.state.target.SEQ}><span>{this.state.target.QUERY_DATA.QUERY_CONTENT}</span><span
                className="btn-close"></span></div>)
        }
        return jsx;
    }


    render(): JSX.Element {
        let layout: JSX.Element = <></>;
        layout = (<>
            <div className="panle-tabContents">
                {this.getWorkTabJSX()}
            </div>
        </>);
        return layout;
    }
}
