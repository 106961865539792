import BIServiceWorker from "../BIServiceWorker";
import {FETCH_EVENT} from "../../../core/transaction/Transaction";


export default class PROJECT_EXPORT extends BIServiceWorker {

    run(param:any, callback?:FETCH_EVENT): void {
        this.transaction()?.post('', param, callback);
    }
}

