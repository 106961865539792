import BIServiceWorker from "../BIServiceWorker";
import {FETCH_EVENT} from "../../../core/transaction/Transaction";


export default class PROJECT_NEW extends BIServiceWorker {

    run(param:any, callback?:FETCH_EVENT): void {
        console.log('PROJECT_NEW run()', param);
    }
}

